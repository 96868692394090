import React, { useEffect, useRef, useState } from "react";
import {
  Modal,
  useTheme,
  Box,
  CircularProgress,
  Typography,
  IconButton,
  Button,
} from "@mui/material";
import { MdClose } from "react-icons/md";

import WheelComponent from "./SpinningWheelWidget";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { xl: "40%", lg: "40%", md: "40%", sm: "40%", xs: "40%" },

  bgcolor: "#ffffffaa",
  p: 5,
  maxHeight: {
    lg: "72vh",
    md: "80vh",
    sm: "90vh",
  },
  overflow: "auto",
  borderRadius: "1em",
  border: 0,
};

const Spinner = ({
  dataItem,
  filterData,
  BackData,
  spinLoading,
  Loading,
  updateLimit,
  voice,
  onPause,
  onPlay,
}) => {
  const [open, setOpen] = useState(true);

  const handleClose = () => {
    setOpen(false);
    onPlay();
  };

  if (open ? true : false) {
    onPause();
  }

  const theme = useTheme();
  const [question, setQuestion] = useState();

  const soundRef = useRef();
  const playActionSound = () => {
    const audioPlayer = soundRef.current;
    audioPlayer?.play();
  };

  
  return (
    <>
      <Modal
        open={open ? true : false}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        closeAfterTransition
      >
        <Box sx={style}>
          <Box
            sx={{
              position: "absolute",
              left: "10%",
              top: "10%",
            }}
          >
            <IconButton onClick={handleClose} size="small">
              <MdClose size={24} color="#000" />
            </IconButton>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography
              variant="h3"
              align="center"
              style={{
                fontWeight: 500,
                color: "#323232",
              }}
            >
              {!question ? "Spinner" : "Congratulation"}
            </Typography>
          </Box>
          <audio ref={soundRef}>
            <source src={`${process.env.PUBLIC_URL}/assets/sounds/win.mpeg`} />
          </audio>
          {spinLoading && (
            <Box
              sx={{
                position: "absolute",
                left: "50%",
                top: "50%",
              }}
            >
              <CircularProgress sx={{ margin: "auto" }} />
            </Box>
          )}
          {!spinLoading && (
            <Box sx={{ position: "relative" }}>
              {!Loading && !question && (
                <WheelComponent
                  segments={dataItem?.map((e) => e.id)}
                  segImg={dataItem?.map((e) => e.img)}
                  segColors={dataItem?.map((e) => e.color)}
                  segSize={dataItem?.map((e) => e.size)}
                  buttonIcon={BackData?.button}
                  logoIcon={BackData?.logo}
                  result={filterData?.map((e) => e)}
                  data={dataItem?.map((e) => e)}
                  frame={BackData?.frame}
                  BackDataSize={BackData?.size}
                  // winningSegment={win}
                  onFinished={(winner) => {
                    setTimeout(() => {
                      updateLimit(dataItem[winner].id, dataItem[winner].limit);
                      setQuestion(dataItem[winner]);
                      playActionSound();
                    }, 500);
                  }}
                  primaryColor="white"
                  contrastColor="white"
                  buttonText=""
                  isOnlyOnce={false}
                  size={100}
                  imgSize={50}
                  imgX={35}
                  imgY={-25}
                  upDuration={100}
                  downDuration={1000}
                  fontFamily="Arial"
                  segmentFontSize={theme.typography.body1.fontSize + "px"}
                />
              )}
            </Box>
          )}

          {question && (
            <Box
              style={{
                textAlign: "center",
                padding: 5,
                overflow: "auto",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <img
                src={question?.img}
                alt=""
                width={200}
                // style={{ transform: " rotate(-0.25turn)" }}
              />
              <Box sx={{ pb: 2 }}>
                <Typography
                  variant="body2"
                  style={{
                    fontWeight: 500,
                    color: "#323232",
                  }}
                >
                  You've earned a {question?.title} with your name on it
                </Typography>
              </Box>
              <Button
                sx={{ justifyContent: "center", display: "flex", py: 3 }}
                fullWidth
                color="secondary"
                variant="contained"
                onClick={handleClose}
              >
                I got it
              </Button>
            </Box>
          )}
        </Box>
      </Modal>
    </>
  );
};

export default Spinner;
