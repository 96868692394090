import { useEffect, useState } from "react";
import {
  fetchSpinner,
  fetchSpinnerItem,
  updateLimitItem,
} from "../../../services/SpinnerWheelService";
import Spinner from "../SpinnerVideo";

const SpinnerComponent = ({ activeStep, onPlay, onPause, voice }) => {
  const [dataItem, setDataItem] = useState([]);
  const [filterData, setFilterData] = useState([]);
  const [BackData, setBackData] = useState([]);
  const [spinLoading, setSpinIsLoading] = useState(true);
  const [Loading, setLoading] = useState(false);

  const updateLimit = async (id, limit) => {
    try {
      setLoading(true);
      limit = limit - 1;
      const resultItem = await updateLimitItem({ id, limit });
      setDataItem((prev) => {
        const currentArr = [...prev];
        const index = currentArr.findIndex((arr) => arr.id === resultItem.id);
        currentArr[index] = resultItem;
        let filterResult = currentArr.filter((item) => item.limit > 0);
        setFilterData(filterResult);

        return currentArr;
      });

      setLoading(false);
    } catch (err) {
      setLoading(false);
      throw err;
    }
  };
  const fetchItem = async (id) => {
    try {
      setSpinIsLoading(true);
      const result = await fetchSpinner(id);
      const resultItem = await fetchSpinnerItem(id);
      const filterResult = await resultItem.filter((item) => item.limit > 0);
      setDataItem(resultItem);
      setBackData(result);
      setFilterData(filterResult);
      setSpinIsLoading(false);
    } catch (err) {
      setSpinIsLoading(false);
      throw err;
    }
  };

  useEffect(() => {
    fetchItem(13);
  }, []);

  return (
    <>
      {activeStep == 1 && (
        <Spinner
          updateLimit={updateLimit}
          dataItem={dataItem}
          filterData={filterData}
          BackData={BackData}
          spinLoading={spinLoading}
          Loading={Loading}
          voice={voice}
          onPause={onPause}
          onPlay={onPlay}
        />
      )}
    </>
  );
};

export default SpinnerComponent;
