import { Grid, Paper, Typography } from "@mui/material";
import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import PostType from "./PostType";
import { getDate } from "../../../shared/getDate";
import UserAvatar from "./../../../components/UserAvatar/index";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { xl: "40%", lg: "40%", md: "40%", sm: "40%", xs: "40%" },

  bgcolor: "#ffffffaa",
  // p: 5,
  maxHeight: {
    lg: "72vh",
    md: "80vh",
    sm: "90vh",
  },
  overflow: "auto",
  borderRadius: "1em",
  border: 0,
};

export default function PostItem({
  post,
  single,
  pageId,
  children = undefined,
  isComment = false,
  user,
  sx,
  searchPost = false,
  home,
  voice,
}) {
  const {
    t,
    i18n: { language: appLang },
  } = useTranslation(["community"]);
  let userId = user?.id;

  const creator = post?.creator;
  const creatorName = post?.creator?.firstName
    ? post?.creator?.firstName + " " + post?.creator?.lastName
    : post?.creator?.username;

  return (
    <Paper
      height={1}
      width={1}
      elevation={0}
      sx={{
        width: "100%",
        border: (searchPost || single) && "none",
        borderRadius: "24px",
        overflow: "hidden",
        ...style,
        ...sx,
      }}
      key={post?.id}
    >
      <Grid
        width={1}
        height={1}
        container
        alignItems="center"
        justifyContent="flex-end"
        sx={{ p: 2, backgroundColor: single && "background.paper" }}
      >
        <Grid item xs={12} container alignItems="center">
          <Grid
            xs={12}
            item
            width={1}
            container
            alignItems="center"
            spacing={2}
          >
            <Grid
              item
              xs={2}
              sm={!searchPost && 1}
              md={!searchPost && 1.6}
              lg={!searchPost && 1}
              xl={!searchPost && 0.8}
            >
              <UserAvatar
                src={creator?.image}
                alt={creatorName}
                color={creator?.color}
                type="user"
              />
            </Grid>
            <Grid
              item
              xs={10}
              sm={!searchPost ? 11 : 10}
              md={!searchPost ? 10.4 : 10}
              lg={!searchPost ? 11 : 10}
              xl={!searchPost ? 11.2 : 10}
            >
              <Box
                sx={{
                  width: 1,
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Box>
                  <Typography variant="body1" color="text.primary">
                    {creatorName}
                  </Typography>
                  <Typography
                    variant="body2"
                    color="text.secondary"
                    sx={{ fontSize: ".8em" }}
                  >
                    {getDate(post?.createdAt, appLang)} .{" "}
                    {post?.type?.poll && ` . poll`}
                  </Typography>
                </Box>
              </Box>
            </Grid>
          </Grid>
          <Grid
            item
            xs={12}
            width={1}
            container
            alignItems="center"
            spacing={2}
            sx={{ my: 1 }}
          >
            <Grid item sm={1} lg={0.8}></Grid>
            <Grid item md={11} xs={12}>
              {post?.type && (
                <PostType
                  type={post?.type}
                  postId={post?.id}
                  userId={userId}
                  creator={post?.creator}
                  searchPost={searchPost}
                  voice={voice}
                />
              )}
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={1}></Grid>
      </Grid>
    </Paper>
  );
}
