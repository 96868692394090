import React, { useCallback, useEffect } from "react";
import "./fonts.css";
import {
  createTheme,
  responsiveFontSizes,
  ThemeProvider,
} from "@mui/material/styles";
import Drawer from "./components/Drawer";
import { Provider } from "react-redux";
import store from "./store";

function App() {
  let theme = createTheme({
    typography: {
      fontFamily: "SofiaProMedium",
    },
  });
  theme = responsiveFontSizes(theme);

  return (
    <ThemeProvider theme={theme}>
      <Provider store={store}>
        <Drawer />
      </Provider>
    </ThemeProvider>
  );
}

export default App;
