import { useState, useRef, useEffect, Fragment, useCallback } from "react";
import { Box } from "@mui/material";
import SeekerBar from "./SeekerBar";
import WaveSurfer from "wavesurfer.js";
import PlayBtn from "../../../SVGs/PlayBtn";
import Overlay from "./Overlay";
import CustomizedSteppers from "./Features";
import FeaturesBar from "./FeaturesBar";
import SpinnerComponent from "./SpinnerComponent";
import PollDisplay from "../LivePoll";
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import { useDispatch, useSelector } from "react-redux";
import InteractiveAction from "../InteractiveVideo/index";
import { getPostsAction } from "../../../store/actions/poll";
import PollsComponent from "./PollsComponent";
import { useVoice } from "../../../hook/useVoice";

function VideoPlayer() {
  const [currentTime, setCurrentTime] = useState(0);
  const [isSeeking, setIsSeeking] = useState(false);
  const [isVideoPaused, setIsVideoPaused] = useState(true);
  const [volume, setVolume] = useState(1);
  const [activeStep, setActiveStep] = useState(0);
  const [totalLength, setTotalLength] = useState(0);
  const [progress, setProgress] = useState(0);

  const videoRef = useRef();
  const waveSurfer = useRef(null);

  const dispatch = useDispatch();

  useEffect(() => {
    if (!waveSurfer?.current) {
      waveSurfer.current = WaveSurfer.create({
        container: "#waveform",
        waveColor: "#b4b4b4",
        progressColor: "#25AAE1",
        height: 75,
        responsive: true,
        cursorColor: "#EA5069",
        cursorWidth: 3,
      });

      videoRef.current.addEventListener("loadedmetadata", () => {
        const duration = videoRef.current.duration;
        setCurrentTime(videoRef.current.currentTime);
        setTotalLength(duration);
        waveSurfer.current.seekTo(videoRef.current.currentTime / duration);
        waveSurfer.current.load(videoRef.current.currentSrc);
      });
      waveSurfer.current.on("audioprocess", () => {
        setCurrentTime(waveSurfer.current.getCurrentTime());
      });
      waveSurfer.current.setVolume(0);
    }
  }, []);

  useEffect(() => {
    if (!isVideoPaused) {
      waveSurfer.current.play();
    } else {
      waveSurfer.current.pause();
    }
  }, [isVideoPaused]);

  const handleVideoPlay = () => {
    setIsVideoPaused(false);
    videoRef.current.play();
    waveSurfer.current.play();
  };

  const handleVideoPause = () => {
    setIsVideoPaused(true);
    videoRef.current.pause();
    waveSurfer.current.pause();
  };

  const handleWaveformSeek = (event) => {
    const waveformWidth = event.target.clientWidth;
    const clickPosition = event.nativeEvent.offsetX;
    const percentage = clickPosition / waveformWidth;
    const seekTime = videoRef.current.duration * percentage;

    setCurrentTime(seekTime);
    videoRef.current.currentTime = seekTime;
    waveSurfer.current.seekTo(percentage);
  };

  const handleVideoSeek = (event) => {
    const seekTime = event.target.currentTime;
    setCurrentTime(seekTime);
    waveSurfer.current.seekTo(seekTime / videoRef.current.duration);

    const videoProgress = (seekTime / videoRef.current.duration) * 100;
    setProgress(videoProgress);

    const activeStepIndex = Math.floor(
      (seekTime / videoRef.current.duration) * 11
    );
    setActiveStep(activeStepIndex);
  };

  const handleWaveformDragStart = () => {
    setIsSeeking(true);
  };

  const handleWaveformDrag = (event) => {
    if (!isSeeking) return;
    handleWaveformSeek(event);
  };

  const handleWaveformDragEnd = () => {
    setIsSeeking(false);
    videoRef.current.currentTime = currentTime;
  };

  const handleVolumeChange = (event) => {
    const volume = event.target.value;
    setVolume(volume);
    videoRef.current.volume = volume;
  };

  const handle = useFullScreenHandle();

  const soundRef = useRef();

  const [currentSegmentIndex, setCurrentSegmentIndex] = useState(1);
  const [currentSegment, setCurrentSegment] = useState();

  const [selectedAction, setSelectedAction] = useState();
  const [updateState, setUpdateState] = useState(false);

  const [videoWidth, setVideoWidth] = useState();
  const [videoHeight, setVideoHeight] = useState();

  const [playing, setPlaying] = useState(false);
  const [muted, setMuted] = useState(false);

  const [livePoll, setLivePoll] = useState(false);
  const [seconds, setSeconds] = useState(0);

  const interactive = useSelector((s) => s.interactive.data);

  useEffect(() => {
    updateVideoSize();
    window.addEventListener("resize", updateVideoSize);

    return () => {
      window.removeEventListener("resize", updateVideoSize);
    };
  }, [handle?.active]);

  useEffect(() => {
    let s =
      Array.isArray(interactive) &&
      interactive?.find((e) => e?.index == currentSegmentIndex);
    setCurrentSegment(s);
  }, [interactive?.length, currentSegmentIndex]);

  const updateVideoSize = () => {
    setVideoWidth(
      handle.active
        ? window?.screen?.width
        : videoRef?.current?.getBoundingClientRect().width
    );
    setVideoHeight(
      handle.active
        ? window?.screen?.height
        : videoRef?.current?.getBoundingClientRect().height
    );
  };

  const refreshState = () => {
    setUpdateState(!updateState);
  };

  const handleSegmentEnd = (instantAction) => {
    if (instantAction != null) {
      setCurrentSegmentIndex(instantAction?.value);
    } else if (selectedAction != null) {
      setCurrentSegmentIndex(selectedAction?.value);
    } else if (!currentSegment?.isLoop) {
      setCurrentSegmentIndex(currentSegment?.index + 1);
    }

    disableAllActions();
    setSelectedAction(null);
  };

  const handleTimeUpdate = (progress) => {
    handleActionsDisplay();
    setSeconds(progress.playedSeconds);
  };

  const handleActionsDisplay = () => {
    let currentTime = videoRef.current?.getCurrentTime();

    if (currentSegment?.actions) {
      for (const [index, action] of currentSegment?.actions?.entries()) {
        let actionTime = action?.delay;

        if (!action.show && currentTime >= actionTime) {
          let newAction = { ...action, show: true };
          let newSegment = { ...currentSegment };
          newSegment.actions[index] = newAction;

          setCurrentSegment(newSegment);

          // action.show = true;
          // refreshState();
        }
      }
    }
  };

  const handleActionClick = async (action) => {
    if (selectedAction == null) {
      setSelectedAction(action);

      playActionSound();
      setPlaying(true);

      if (currentSegment?.isLoop) {
        handleSegmentEnd(action);
      }
    }
  };

  const disableAllActions = () => {
    if (currentSegment?.actions) {
      for (let action of currentSegment?.actions) {
        action = { ...action, show: false };
        refreshState();
      }
    }
  };

  const playActionSound = () => {
    const audioPlayer = soundRef.current;
    audioPlayer?.play();
  };
  const voice = useVoice();

  return (
    <Box sx={{ width: 1 }}>
      <Box
        sx={{
          position: "relative",
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <video
          src="./images/videoTest.mp4"
          poster={"/images/videobgd.png"}
          style={{ width: "100%", objectFit: "cover" }}
          onPlay={handleVideoPlay}
          onPause={handleVideoPause}
          onTimeUpdate={handleVideoSeek}
          onLoadedMetadata={() => {
            const duration = videoRef.current.duration;
            setCurrentTime(videoRef.current.currentTime);
            waveSurfer.current.seekTo(videoRef.current.currentTime / duration);
          }}
          ref={videoRef}
        />

        {activeStep == 6 && (
          <PollsComponent
            onPlay={handleVideoPlay}
            onPause={handleVideoPause}
            voice={voice}
          />
        )}
        <SpinnerComponent
          activeStep={activeStep}
          onPlay={handleVideoPlay}
          onPause={handleVideoPause}
          voice={voice}
        />
        <Fragment>
          <PollDisplay
            width={1920}
            livePoll={activeStep == 4}
            onPlay={handleVideoPlay}
            onPause={handleVideoPause}
            voice={voice}
          />
        </Fragment>
        {activeStep == 7 && (
          <Box
            sx={{
              position: "absolute",
              bottom: 40,
              left: 0,
              right: 0,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              flexWrap: "wrap",
              width: 1,
              zIndex: 11,
            }}
          >
            {currentSegment?.actions?.map((action, index) => (
              <InteractiveAction
                key={index}
                action={action}
                disabled={selectedAction !== null && action !== selectedAction}
                onClick={() => handleActionClick(action)}
                isSelected={action === selectedAction}
                animate={!currentSegment?.isLoop}
                videoWidth={videoWidth}
                videoHeight={videoHeight}
                fullScreen={handle}
                handleSegmentEnd={handleSegmentEnd}
                onPlay={handleVideoPlay}
                onPause={handleVideoPause}
                voice={voice}
              />
            ))}
          </Box>
        )}
        {isVideoPaused && (
          <>
            <Box
              sx={{
                position: "absolute",
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                width: "100%",
                height: "100%",
                zIndex: 10,
                fill: "#f9f9f9",
                stroke: "#fff",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Box
                sx={{
                  width: {
                    xs: "30px",
                    sm: "60px",
                    md: "80px",
                    xl: "100px",
                  },
                  cursor: "pointer",
                  transition: "0.5s all",
                }}
                onClick={handleVideoPlay}
              >
                <PlayBtn />
              </Box>
            </Box>

            <Box
              sx={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                zIndex: 1,
                pointerEvents: "none",
              }}
            >
              <img
                src="/images/videobgd.svg"
                alt=""
                style={{ width: "100%", height: "100%", objectFit: "cover" }}
              />
            </Box>
          </>
        )}

        {!isVideoPaused && (
          <Box
            sx={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              zIndex: 10,
            }}
            onClick={handleVideoPause}
          ></Box>
        )}

        <Overlay volume={volume} handleVolumeChange={handleVolumeChange} />
      </Box>
      <CustomizedSteppers
        activeStep={activeStep}
        progress={progress}
        totalLength={totalLength}
      />
      {/* <FeaturesBar /> */}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <SeekerBar
          handleWaveformSeek={handleWaveformSeek}
          handleWaveformDragStart={handleWaveformDragStart}
          handleWaveformDrag={handleWaveformDrag}
          handleWaveformDragEnd={handleWaveformDragEnd}
          currentTime={currentTime}
          videoRef={videoRef}
          isVideoPaused={isVideoPaused}
        />
      </Box>
    </Box>
  );
}

export default VideoPlayer;
